<template>
  <Loading class="min-h-screen" :loading="loading" />
  <div v-if="board?.id">
    <!-- Board heading -->
    <div class="board-heading py-6 px-4 bg-fixed" :style="{ background: board.titleBg }">
      <!-- Setting buttons -->
      <div class="flex gap-2 mb-2">
        <!-- View button -->
        <a-tooltip>
          <template #title>
            <span>View as recipient</span>
          </template>
          <a-button ghost shape="circle" @click="gotoBoardView">
            <template #icon><EyeOutlined /></template>
          </a-button>
        </a-tooltip>

        <!-- Share button -->
        <a-tooltip>
          <template #title>
            <span>Share this board</span>
          </template>
          <a-button ghost shape="circle" @click="modalShareVisible = true">
            <template #icon><ShareAltOutlined /></template>
          </a-button>
        </a-tooltip>

        <!-- Background -->
        <a-tooltip v-if="isOwner">
          <template #title>
            <span>Change background</span>
          </template>
          <a-button ghost shape="circle" @click="modalVisibleBackground = true">
            <template #icon><BgColorsOutlined /></template>
          </a-button>
        </a-tooltip>

        <!-- Fonts -->
        <a-tooltip v-if="isOwner">
          <template #title>
            <span>Change font</span>
          </template>
          <a-button ghost shape="circle" @click="modalVisibleFont = true">
            <template #icon><FontColorsOutlined /></template>
          </a-button>
        </a-tooltip>

        <!-- Schedule button -->
        <a-tooltip v-if="isOwner">
          <template #title>
            <span>Schedule this board</span>
          </template>
          <a-button ghost shape="circle" @click="modalBoardScheduleFormVisible = true">
            <template #icon><SendOutlined /></template>
          </a-button>
        </a-tooltip>

        <!-- Setting -->
        <a-tooltip v-if="isOwner">
          <template #title>
            <span>Setting</span>
          </template>
          <a-button ghost shape="circle" @click="modalVisible = true">
            <template #icon><SettingOutlined /></template>
          </a-button>
        </a-tooltip>

        <!-- Emoji -->
        <a-tooltip v-if="isOwner">
          <template #title>
            <span>Emoji</span>
          </template>
          <BoardEmojiSelector
            :isShowEmojiList="isShowEmoji"
            :isGhost="true"
            :listClass="'absolute z-10 mx-20 my-10'"
            @update:showEmoji="isShowEmoji = $event"
            @update:onClose="isShowEmoji = $event"
            @update:emoji="boardTitleChanged(boardTitle += $event)"
          />
        </a-tooltip>
      </div>
      <!-- Board title -->
      <div class="mb-2" :style="{ 'font-family': board.fontFamily }">
        <a-input
          size="large"
          class="board-heading__input-title"
          v-model:value="boardTitle"
          @blur="boardTitleChanged"
          placeholder="board title"
          :readonly="!isOwner"
        />
      </div>

      <!-- Add to board button -->
      <div class="text-right" v-if="canAddCard">
        <a-button size="large" shape="round" @click="modalVisibleBoardItem = true">
          Add card
          <template #icon>
            <PlusCircleOutlined />
          </template>
        </a-button>
      </div>
    </div>
    <!-- Board items -->
    <div
      class="board-container"
      :style="[
        {
          'font-family': board.fontFamily,
        },
        boardBackground(board.boardBg),
      ]"
      :class="[board.boardBg.includes('http') ? 'bg-fixed bg-center bg-cover bg-no-repeat' : '']"
    >
      <BoardViewItems v-if="board.id" :board="board" />
    </div>

    <!-- Model form -->
    <BoardFormModal
      v-if="modalVisible"
      :modalVisible="modalVisible"
      :id="board.id"
      @update:closeModal="onCloseModalBoardSetting"
    />

    <BoardFormScheduleModal
      v-if="modalBoardScheduleFormVisible"
      :modalVisible="modalBoardScheduleFormVisible"
      :id="board.id"
      @update:closeModal="onCloseModalBoardSetting"
    />

    <BoardBackgroundSelect
      v-if="modalVisibleBackground"
      :modalVisible="modalVisibleBackground"
      :id="board.id"
      @update:closeModal="modalVisibleBackground = false"
      @update:onSubmit="onBackgroundSelected"
      @onCreateNew=";(modalBoardBgBuilder = true), (isEditMode = fasle)"
      @onEditBg="onEditBg"
    />

    <BoardFontSelect
      v-if="modalVisibleFont"
      :modalVisible="modalVisibleFont"
      :id="board.id"
      @update:closeModal="modalVisibleFont = false"
      @update:onSubmit="onFontSelected"
    />
    <BoardItemFormModal
      v-if="modalVisibleBoardItem"
      :modalVisible="modalVisibleBoardItem"
      :board="board"
      @update:closeModal="modalVisibleBoardItem = false"
      @update:onSubmit="onAddBoardItemDone"
    />

    <BoardShareModal
      v-if="modalShareVisible"
      :modalVisible="modalShareVisible"
      :board="board"
      @update:closeModal="modalShareVisible = false"
    />

    <BoardBgBuilder
      v-if="modalBoardBgBuilder"
      :modalVisible="modalBoardBgBuilder"
      :isEditMode="isEditMode"
      :data="bgEditItem"
      @update:closeModal="modalBoardBgBuilder = false"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IBoard, IBoardBackGround, IUser } from '@/utils/types'
import { CLEAR_BOARD, LOAD_BACKGROUND, LOAD_BOARD, UPDATE_BOARD, LOAD_FONTS } from '@/store/actions'
import { boardBackground, appendCssFont } from '@/utils/helpers'
import {
  EyeOutlined,
  SettingOutlined,
  BgColorsOutlined,
  FontColorsOutlined,
  PlusCircleOutlined,
  ShareAltOutlined,
  SendOutlined,
} from '@ant-design/icons-vue'
import BoardFormScheduleModal from '@/components/board/BoardFormScheduleModal.vue'
import BoardFormModal from '@/components/board/BoardFormModal.vue'
import BoardBackgroundSelect from '@/components/board/BoardBackgroundSelect.vue'
import BoardFontSelect from '@/components/board/BoardFontSelect.vue'
import BoardViewItems from '@/components/board/BoardViewItems.vue'
import BoardItemFormModal from '@/components/board/BoardItemFormModal.vue'
import BoardShareModal from '@/components/board/BoardShareModal.vue'
import BoardBgBuilder from '@/components/images/BoardBgBuilder.vue'
import Loading from '@/components/common/Loading.vue'
import { Watch } from 'vue-property-decorator'
import { BOARD_PERMISSION_TYPE } from '@/utils/constants'
import BoardEmojiSelector from '@/components/board/BoardEmojiSelector.vue'

@Options({
  components: {
    BoardFormModal,
    BoardFormScheduleModal,
    BoardItemFormModal,
    BoardFontSelect,
    BoardBackgroundSelect,
    BoardViewItems,
    BoardShareModal,
    BoardBgBuilder,
    BoardEmojiSelector,
    SendOutlined,
    EyeOutlined,
    ShareAltOutlined,
    SettingOutlined,
    FontColorsOutlined,
    BgColorsOutlined,
    PlusCircleOutlined,
    Loading,
  },
  directives: { maska },
})
export default class BoardEdit extends Vue {
  modalVisible = false
  modalVisibleBackground = false
  modalVisibleFont = false
  modalVisibleBoardItem = false
  modalShareVisible = false
  modalBoardScheduleFormVisible = false

  modalBoardBgBuilder = false
  bgEditItem = {}
  isEditMode = false

  boardTitle = ''

  boardBackground = boardBackground

  isShowEmoji = false

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get board(): IBoard {
    const boardItem = this.$store.state.board.item
    const fontsList = this.$store.state.board.fontsList

    if (boardItem?.fontFamily) {
      const selectedFont = fontsList.find(({ family }: { family: string }) => family === boardItem.fontFamily)
      appendCssFont(selectedFont?.family || boardItem.fontFamily, selectedFont?.variants, selectedFont?.subsets)
    }

    return boardItem
  }

  get id() {
    return this.$route.params.id
  }

  get isOwner() {
    return this.userInfo.id === this.board.userId
  }

  get isPublicBoard() {
    return this.board.boardType === BOARD_PERMISSION_TYPE.public
  }

  get isPrivateBoard() {
    return this.board.boardType === BOARD_PERMISSION_TYPE.private
  }

  get canAddCardToPrivateBoard() {
    return (
      this.board.boardType === BOARD_PERMISSION_TYPE.private &&
      this.board.contributorEmails?.includes(this.userInfo.email)
    )
  }

  get isPasswordBoard() {
    return this.board.boardType === BOARD_PERMISSION_TYPE.password
  }

  get canAddCard() {
    return this.isOwner || this.isPublicBoard || this.canAddCardToPrivateBoard || this.isPasswordBoard
  }

  @Watch('board')
  boardChanged() {
    if (this.board) {
      this.boardTitle = this.board.title || ''
    }
  }

  boardTitleChanged(newTitle?: string) {
    if (this.boardTitle || newTitle) {
      this.$store.dispatch(UPDATE_BOARD, {
        ...this.board,
        title: this.boardTitle || newTitle,
      })
    }
  }

  onAddBoardItemDone() {
    this.modalVisibleBoardItem = false
  }

  onCloseModalBoardSetting() {
    this.modalVisible = false
    this.modalBoardScheduleFormVisible = false
    this.getBoard()
  }

  onBackgroundSelected() {
    this.getBoard()
    this.modalVisibleBackground = false
  }

  onFontSelected() {
    this.getBoard()
    this.modalVisibleFont = false
  }

  gotoBoardView() {
    this.$router.push({
      name: 'board_view',
      params: {
        id: this.board.id,
      },
    })
  }

  onEditBg(item: IBoardBackGround) {
    this.bgEditItem = item
    this.isEditMode = true
    this.modalBoardBgBuilder = true
  }

  beforeUnmount() {
    this.$store.dispatch(CLEAR_BOARD)
  }

  async getBoard() {
    await this.$store.dispatch(LOAD_BOARD, this.id)
  }

  async getBackgrounds() {
    await this.$store.dispatch(LOAD_BACKGROUND, this.userInfo)
  }

  async getFontsList() {
    await this.$store.dispatch(LOAD_FONTS)
  }

  async created() {
    await this.getFontsList()

    if (this.id) {
      await this.getBoard()
    }

    if (this.userInfo) {
      await this.getBackgrounds()
    }
  }
}
</script>
