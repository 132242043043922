import axios from 'axios'
import store from '@/store'
import { OPEN_DIALOG } from '@/store/actions'
import { UNSPLASH_API_KEY } from '@/utils/constants'

const axiosInstance = axios.create({
    baseURL: 'https://api.unsplash.com/',
    timeout: 5000,
    withCredentials: false,
    headers: {
        Authorization: `Client-ID ${UNSPLASH_API_KEY}`,
    },
})

axiosInstance.interceptors.response.use((response) => {
    return response
}, (error) => {
    const errors = error.response?.data.errors
    switch (error.response?.status) {
        default:
            store.commit(OPEN_DIALOG, {
                title: 'Error',
                message: errors,
                confirmation: true,
                type: 'danger',
            })
            break
    }
})

const searchImages = async (keyword: string, currentPage = 1, perPage = 10): Promise<{ total: number, total_pages: number, results: never[] }> => {
    const response = await axiosInstance.get('/search/photos', {
        params: {
            query: keyword,
            page: currentPage,
            per_page: perPage,
            orientation: 'landscape',
        },
    })

    return response?.data
}

export {
    searchImages,
}
