
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { boardBackground } from '@/utils/helpers'
import { BOARD_BACKGROUND_LIST } from '@/utils/constants'

import { ADD_BACKGROUND, LOAD_BACKGROUND, UPDATE_BACKGROUND } from '@/store/actions'

import { searchImages } from '@/services/image-search'
import { IBoardBackGround, IUser } from '@/utils/types'

// From components
import Loading from '@/components/common/Loading.vue'

@Options({
  components: {
    Loading,
  },
})
export default class BoadBgBuilder extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop({ type: Boolean, default: false })
  isEditMode = false

  @Prop({ type: Object }) data = {}

  boardBackground = boardBackground

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  rules = {
    titleBg: [this.requiredRule],
    boardBg: [this.requiredRule],
  }

  form = {
    boardBg: BOARD_BACKGROUND_LIST[0].boardBg,
    titleBg: BOARD_BACKGROUND_LIST[0].titleBg,
    group: 'pattern',
    boardBgThumb: BOARD_BACKGROUND_LIST[0].boardBgThumb,
  }

  searchImageKeyword = ''
  searchImageResults = []
  searchingImage = false
  searchPagination = {
    page: 1,
    perPage: 20,
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async onSearchImage(keyword?: string, merge = false) {
    if (!keyword && !this.searchImageKeyword) {
      return
    }

    this.searchingImage = true

    const searchWord = keyword || this.searchImageKeyword
    this.searchImageKeyword = searchWord

    const { results } = await searchImages(searchWord, this.searchPagination.page, this.searchPagination.perPage)

    this.searchingImage = false
    if (merge) {
      return (this.searchImageResults = this.searchImageResults.concat(results))
    }
    this.searchImageResults = results
  }

  onListSearchImgScroll(event: Event) {
    const target = event.target as HTMLElement
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 0.5) {
      this.searchPagination.page += 1
      this.onSearchImage(this.searchImageKeyword, true)
    }
  }

  onSelectImage(image: {
    urls: {
      full: string
      thumb: string
      small: string
    }
  }) {
    this.form.boardBg = image.urls.full
    this.form.boardBgThumb = image.urls.thumb || image.urls.small
  }

  async onCreateBg() {
    let actionPath = ADD_BACKGROUND
    if (this.isEditMode) {
      actionPath = UPDATE_BACKGROUND
    }

    if (this.form.group === 'solid') {
      this.form.boardBgThumb = this.form.boardBg
    }

    await this.$store.dispatch(actionPath, { background: this.form, user: this.userInfo })
    await this.$store.dispatch(LOAD_BACKGROUND, this.userInfo)

    this.onCancel()
  }

  mounted() {
    if (this.isEditMode) {
      this.form = JSON.parse(JSON.stringify(this.data)) as IBoardBackGround
    }
  }
}
