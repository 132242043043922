<template>
  <a-modal width="1000px" class="max-w-full" :visible="modalVisible" @cancel="onCancel" title="Create background">
    <a-row :gutter="[8, 8]">
      <a-col :span="8">
        <h2 class="text-md">Creator</h2>
        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
          <a-form-item label="Head color" name="titleBg">
            <a-input class="h-16" v-model:value="form.titleBg" type="color" />
          </a-form-item>

          <a-form-item label="Body image" name="boardBg">
            <a-tabs v-model:activeKey="form.group">
              <a-tab-pane key="pattern" tab="Patterns">
                <a-input-search
                  v-model:value="searchImageKeyword"
                  placeholder="input search text"
                  class="mt-2"
                  @search="onSearchImage"
                />

                <div class="my-2 text-right">
                  <a @click="onSearchImage('birthday')"> Birthday </a>
                  <span> | </span>

                  <a @click="onSearchImage('teamwork')"> Teamwork </a>
                  <span> | </span>

                  <a @click="onSearchImage('natural')"> Natural </a>
                </div>

                <Loading :loading="searchingImage" />
                <div @scroll="onListSearchImgScroll" class="flex max-h-64 overflow-y-scroll flex-wrap">
                  <div class="w-1/2 pr-1 mb-1" v-for="resultImg in searchImageResults" :key="resultImg.id">
                    <img
                      class="w-full h-full"
                      :src="resultImg.urls.thumb || resultImg.urls.small"
                      alt="resultImg.alt_description"
                      @click="onSelectImage(resultImg)"
                    />
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="solid" tab="Solids" force-render>
                <a-input class="h-16" v-model:value="form.boardBg" type="color" />
              </a-tab-pane>
            </a-tabs>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="16">
        <h2 class="text-md">Preview</h2>
        <div class="preview-bg">
          <div class="board-bg-item cursor-pointer">
            <div class="board-bg-item__title h-20" :style="{ background: form.titleBg }"></div>
            <div
              class="board-bg-item__bg bg-center bg-cover bg-no-repeat h-96"
              :style="[
                form.group == 'pattern'
                  ? { backgroundImage: `url(${form.boardBg})` }
                  : { backgroundColor: form.boardBg },
              ]"
            ></div>
          </div>
        </div>
      </a-col>
    </a-row>
    <template #footer>
      <a-button key="back" @click="onCreateBg">{{ isEditMode ? 'EDIT' : 'CREATE' }}</a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { boardBackground } from '@/utils/helpers'
import { BOARD_BACKGROUND_LIST } from '@/utils/constants'

import { ADD_BACKGROUND, LOAD_BACKGROUND, UPDATE_BACKGROUND } from '@/store/actions'

import { searchImages } from '@/services/image-search'
import { IBoardBackGround, IUser } from '@/utils/types'

// From components
import Loading from '@/components/common/Loading.vue'

@Options({
  components: {
    Loading,
  },
})
export default class BoadBgBuilder extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop({ type: Boolean, default: false })
  isEditMode = false

  @Prop({ type: Object }) data = {}

  boardBackground = boardBackground

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  rules = {
    titleBg: [this.requiredRule],
    boardBg: [this.requiredRule],
  }

  form = {
    boardBg: BOARD_BACKGROUND_LIST[0].boardBg,
    titleBg: BOARD_BACKGROUND_LIST[0].titleBg,
    group: 'pattern',
    boardBgThumb: BOARD_BACKGROUND_LIST[0].boardBgThumb,
  }

  searchImageKeyword = ''
  searchImageResults = []
  searchingImage = false
  searchPagination = {
    page: 1,
    perPage: 20,
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async onSearchImage(keyword?: string, merge = false) {
    if (!keyword && !this.searchImageKeyword) {
      return
    }

    this.searchingImage = true

    const searchWord = keyword || this.searchImageKeyword
    this.searchImageKeyword = searchWord

    const { results } = await searchImages(searchWord, this.searchPagination.page, this.searchPagination.perPage)

    this.searchingImage = false
    if (merge) {
      return (this.searchImageResults = this.searchImageResults.concat(results))
    }
    this.searchImageResults = results
  }

  onListSearchImgScroll(event: Event) {
    const target = event.target as HTMLElement
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 0.5) {
      this.searchPagination.page += 1
      this.onSearchImage(this.searchImageKeyword, true)
    }
  }

  onSelectImage(image: {
    urls: {
      full: string
      thumb: string
      small: string
    }
  }) {
    this.form.boardBg = image.urls.full
    this.form.boardBgThumb = image.urls.thumb || image.urls.small
  }

  async onCreateBg() {
    let actionPath = ADD_BACKGROUND
    if (this.isEditMode) {
      actionPath = UPDATE_BACKGROUND
    }

    if (this.form.group === 'solid') {
      this.form.boardBgThumb = this.form.boardBg
    }

    await this.$store.dispatch(actionPath, { background: this.form, user: this.userInfo })
    await this.$store.dispatch(LOAD_BACKGROUND, this.userInfo)

    this.onCancel()
  }

  mounted() {
    if (this.isEditMode) {
      this.form = JSON.parse(JSON.stringify(this.data)) as IBoardBackGround
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
